import React, { Component } from 'react';
import PageNotFound from './components/common/pageNotFound'
import SignWrapper from './components/signWrapper/signWrapper.js'
import { Route, Switch } from "react-router-dom";
import './App.css';
import ThankYou from './components/common/thankYou.js';
import LinkExpired from './components/common/linkExpired.js';
import AlreadySigned from './components/common/alreadySigned';


class App extends Component {
  
  render() {
    var ES6Promise = require("es6-promise");
    ES6Promise.polyfill();  
    return (
      <React.Fragment>
        <Switch>
            <Route exact path="/" component={PageNotFound} />} />
            <Route exact path="/:id" component={SignWrapper} />} />
            <Route exact path="/digiSign/thankYou" component={ThankYou} />} />
            <Route exact path="/digiSign/linkExpired" component={LinkExpired} />} />
            <Route exact path="/digiSign/alreadySigned" component={AlreadySigned} />} />
         </Switch>
      </React.Fragment>
      
    );
  }
}

export default App;
