import React from 'react';
import MyHeader from './header';
import MyFooter from './footer';

    
class PageNotFound extends React.Component {

    render() {
        return (
            <React.Fragment>
                <MyHeader />
                <h2 className = 'pageNotFound'>Page Not Found !</h2>
                <MyFooter />
            </React.Fragment>
        )
      }
}
export default PageNotFound;