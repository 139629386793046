import React from 'react';
    
class MyHeader extends React.Component {

    render() {
        return (
            <header className="prime-header">
                <img src="/logo.svg" className="logo" alt="" />
            </header>
        )
      }
}
export default MyHeader;