import React from 'react';
import MyPdfViewer from '../pdfViewer/pdfViewer.js';
import PDFJs from '../pdfViewer/pdfjs.js';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import * as path from "../../constants/constants.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import saveAs from 'file-saver';
import MyHeader from '../common/header.js';
import MyFooter from '../common/footer.js';



class SignWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           buttonActive:true,
           checkBoxDisable:true,
           iframeSrc:'',
           id:'',
           memberId:'',
           memberAgreementId:'',
           open: false,
           loading:false,
           downloaded:false,
           isLinkExpired:false,
           isAgreementSigned:false,
           isEmpty:true
        }
        this.conditionCheckBox = React.createRef();
        this.checkAgreement = this.checkAgreement.bind(this);
        this.saveSign = this.saveSign.bind(this);
        this.clearSign = this.clearSign.bind(this);
        this.enableCheckBox = this.enableCheckBox.bind(this);
        this.resizedataURL = this.resizedataURL.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.isLinkExpired = this.isLinkExpired.bind(this);
        this.changeEmptyToFalse = this.changeEmptyToFalse.bind(this);
        this.changeEmptyToTrue = this.changeEmptyToTrue.bind(this);
        this.isAgreementSigned = this.isAgreementSigned.bind(this);

    }

    changeEmptyToFalse(){
        this.setState({
            isEmpty:false
        })
    }

    changeEmptyToTrue(){
        this.setState({
            isEmpty:true
        })
    }

    shouldComponentUpdate(props) {
        return true;
   }

   componentWillMount(){
    let token  = this.props.match.params.id;
    let url = path.PROD_URL +'/getMemberData';
    axios.get(url,{params:{
        token: token
    }})
    .then(response => {
        //checking whether link has expired or not 
        if(this.isLinkExpired(response.data.currentDate,response.data.LastEmailSentAt)){
            this.setState({
                isLinkExpired:true
            })
        }
        if(this.isAgreementSigned(response.data.IsSigned)){
            this.setState({
                isAgreementSigned:true
            })
        }
        
        this.setState({
            memberAgreementId:response.data.MemberAgreementId,
            memberId:response.data.MemberId,
            id:response.data.Id
            })
        }).catch(error => {
            this.notifyError();
                // console.log(error);
            });

    }

    componentDidMount() {
        let token = this.props.match.params.id;
        let url = path.PROD_URL + '/getUnsignedPdf';

        axios.get(url, {
            params: {
                token: token
            },
            responseType: 'blob'
        })
            .then(response => {
                if(response.data.size===513)
                {
                    this.notifyErrorInternetConnection();
                }
                if(response.data.size<10)
                {
                    this.notifyErrorPdf();
                }
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                // window.location.assign(fileURL);
                this.setState({
                    iframeSrc: fileURL
                });
                // window.open(fileURL);
                let flag = false,
                prevX = 0,
                currX = 0,
                prevY = 0,
                currY = 0,
                dot_flag = false;

        let penColor = "black",
            thickness = 3;
        const canvas = this.refs.canvas;
        const that = this;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            let canvaswidth = canvas.width;
            let canvasHeight = canvas.height;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvaswidth, canvasHeight);
            //prevent scrolling while touching the canvas
            document.body.addEventListener("touchstart", function (e) {
                if (e.target === canvas) {
                    e.preventDefault();
                }
            }, { passive: false });
            document.body.addEventListener("touchend", function (e) {
                if (e.target === canvas) {
                    e.preventDefault();
                }
            }, { passive: false });
            document.body.addEventListener("touchmove", function (e) {
                if (e.target === canvas) {
                    e.preventDefault();
                }
            }, { passive: false });
            //Bind touch event listeners
            canvas.addEventListener("touchstart", function (e) {
                let touch = e.touches[0];
                let mouseEvent = new MouseEvent("mousedown", {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                canvas.dispatchEvent(mouseEvent);
            }, false);
            canvas.addEventListener("touchend", function (e) {
                let mouseEvent = new MouseEvent("mouseup", {});
                canvas.dispatchEvent(mouseEvent);
            }, false);
            canvas.addEventListener("touchmove", function (e) {
                let touch = e.touches[0];
                let mouseEvent = new MouseEvent("mousemove", {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                canvas.dispatchEvent(mouseEvent);
            }, false);

            canvas.addEventListener("mousemove", function (e) {
                findxy('move', e)
            }, false);

            canvas.addEventListener("mousedown", function (e) {
                findxy('down', e)
            }, false);

            canvas.addEventListener("mouseup", function (e) {
                findxy('up', e)
            }, false);

            canvas.addEventListener("mouseout", function (e) {
                findxy('out', e)
            }, false);

            function draw() {
                that.changeEmptyToFalse();     
                ctx.beginPath();
                ctx.moveTo(prevX, prevY);
                ctx.lineTo(currX, currY);
                ctx.strokeStyle = penColor;
                ctx.lineWidth = thickness;
                ctx.stroke();
                ctx.closePath();
            }
            function findxy(res, e) {
                if (res === 'down') {
                    prevX = currX;
                    prevY = currY;
                    currX = e.clientX - canvas.getBoundingClientRect().left;
                    currY = e.clientY - canvas.getBoundingClientRect().top;
                    flag = true;
                    dot_flag = true;
                    if (dot_flag) {
                        ctx.beginPath();
                        ctx.fillStyle = penColor;
                        ctx.fillRect(currX, currY, 2, 2);
                        ctx.closePath();
                        dot_flag = false;
                    }
                }
                if (res === 'up' || res === "out") {
                    flag = false;
                }
                if (res === 'move') {
                    if (flag) {
                        prevX = currX;
                        prevY = currY;
                        currX = e.clientX - canvas.getBoundingClientRect().left;
                        currY = e.clientY - canvas.getBoundingClientRect().top;
                        draw();
                    }
                }
            }
        }

            })
            .catch(error => {
                // window.alert(error);
            });
        // storing the member info in state
        // canvas signing code
        
    }
    // to check if link is expired or not
    isLinkExpired(currentDate,lastEmailDate,isSigned){
        let difference = currentDate - lastEmailDate;
        let daysDifference = Math.floor(difference/60/60/24);
        if(daysDifference>path.EXPIRATION_DAYS)
            return true;
        return false;
    }

    isAgreementSigned(isSigned){
        return isSigned;
    }

    //toasters for different success and failure conditions
    notify = () => {
        toast("Signature Submitted Successfully !", {
            position: toast.POSITION.TOP_CENTER,
            className: 'alert'
        });
    }
    notifyError=()=>{
        toast.error("There is some Network Error. Please Try Again!", {
            position: toast.POSITION.TOP_CENTER
        });   
    }

    notifyErrorPdf = () =>{
        toast.error("Sorry! The Pdf file is Corrupted. Please Try Again!", {
            position: toast.POSITION.TOP_CENTER
        });
    }

    notifyErrorInternetConnection = () =>{
        toast.error("Please check your Internet Connection", {
            position: toast.POSITION.TOP_CENTER
        });
    }

    notifyNoSignature = () =>{
        toast.error("Submission Requires Signature", {
            position: toast.POSITION.TOP_CENTER
        });
    }

    //save sign captured on the canvas
    async saveSign() {
        if(this.state.isEmpty === true)
        {
            this.notifyNoSignature();
        }
        else
        {
            this.setState({
                loading: true
            })
            const canvas = this.refs.canvas;
            var dataURL = canvas.toDataURL();
            var newDataUri = await this.resizedataURL(dataURL, 1200, 400);
            var base64 = newDataUri.replace(/^data:image\/(png|jpg);base64,/, "");
            let url = path.PROD_URL + '/sign';
            axios.post(url, {
                memberAgreementId: this.state.memberAgreementId,
                memberId: this.state.memberId,
                base64: base64,
                id:this.state.id
            })
            .then((res)=>{
                //now downloading signed document.
                let url = path.PROD_URL +'/getSignedPdf';
                axios.get(url,{params:{
                    memberAgreementId: this.state.memberAgreementId,
                    memberId: this.state.memberId,
                },
                responseType: 'blob'})
                        .then(response => {
                            // const url = window.URL.createObjectURL(new Blob([response.data]));
                            let downloadedFileName = 'SignedCrunchAgreement_'+ this.state.memberAgreementId+"_"  + new Date().toDateString().replace(new RegExp(' ', 'g'), '_') + '.pdf';
                            var blob = new Blob([response.data],{
                                type:'application/pdf'
                            });
                            saveAs(blob, downloadedFileName);
                            // const link = document.createElement('a');
                            // link.href = url;
                            // link.setAttribute('download', downloadedFileName);
                            // document.body.appendChild(link);
                            // link.click();
                            this.notify();
                            this.setState({
                                loading: false,
                                downloaded:true
                            });
                            
                        })
                        .catch(error => {
                            window.alert(error);
                        });
                }).catch((err) => {
                    window.alert(err);
                })
        }
        
    }

    resizedataURL(datas, wantedWidth, wantedHeight) {
        return new Promise(async function (resolve, reject) {

            // We create an image to receive the Data URI
            var img = document.createElement('img');

            // When the event "onload" is triggered we can resize the image.
            img.onload = function () {
                // We create a canvas and get its context.
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');

                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

                var dataURI = canvas.toDataURL();

                // This is the return of the Promise
                resolve(dataURI);
            };

            // We put the Data URI in the image's src attribute
            img.src = datas;

        })
    }

    //resetting the canvas
    clearSign() {
        this.changeEmptyToTrue();
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    //to enable the check box and the signing canvas
    checkAgreement() {
        if (this.state.buttonActive === true) {
            this.setState({
                buttonActive: false
            });
            this.refs.disabledOverlay.style.display = 'none';
        }
        if (this.state.buttonActive === false) {
            this.setState({
                buttonActive: true
            });
            this.refs.disabledOverlay.style.display = 'block';
        }
    }

    enableCheckBox() {
        this.setState({
            checkBoxDisable: false
        })
    }

    onOpenModal() {
        this.setState({ open: true });
    };

    onCloseModal() {
        this.setState({ open: false });
    };

    render() {
        const { loading } = this.state;
        if(this.state.downloaded === true)
        {
            return <Redirect to='/digisign/thankYou' />
        }
        if(this.state.isAgreementSigned===true){
            return <Redirect to='/digisign/alreadySigned' />
        }
        if(this.state.isLinkExpired === true){
            return <Redirect to='/digisign/linkExpired' />
        }
        if(this.state.memberId===''){
            return (
                <React.Fragment>
                    <div className="loader">
                    </div>
                    <ToastContainer autoClose={false}/>
                </React.Fragment>)
        }
        return (
            <React.Fragment>
                <MyHeader/>
                <LoadingOverlay
                    active={loading}
                    spinner styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(0, 0, 0, 0.23)'
                        })
                    }}>
                    <MyPdfViewer backend={PDFJs} src={this.state.iframeSrc} enableCheckBox={this.enableCheckBox} checkBoxDisable={this.state.checkBoxDisable} />
                    <div className="grd-container">
                        <div className="container">
                            <label className="checkbox">
                                <input type="checkbox" disabled={this.state.checkBoxDisable} id="checkAgree" name="checkAgree" onClick={this.checkAgreement} value="I agree to all membership terms and conditions" />
                                <span> I agree to all membership terms and conditions (Please scroll and review document in its entirety to enable this checkbox)</span>
                            </label>
                        </div>
                    </div>


                    <div className="sign-wrapper">
                        <span className="header">SIGN HERE</span>
                        <canvas ref="canvas" id="can" className="canvas" ></canvas>
                        <div ref="disabledOverlay" className="disabled-overlay" id="disabledOverlay"></div>
                    </div>
                    <div className="button-holder">
                        <input type="button" className="submitBtn" disabled={this.state.buttonActive} value="Submit and Download" id="btn" onClick={this.saveSign} />
                        <input type="button" disabled={this.state.buttonActive} value="Clear" id="clr" onClick={this.clearSign} />
                    </div>
                    <ToastContainer autoClose={1500}/>
                </LoadingOverlay>
                <MyFooter />
            </React.Fragment>
        )
    }
}
export default SignWrapper;