import React from 'react';
import MyFooter from './footer';
import MyHeader from './header';
    
class AlreadySigned extends React.Component {

    render() {
        return (
            <React.Fragment>
                <MyHeader />
                    <h2 className = "alreadySigned">Your Signature has already been recieved. Please contact your <a href="https://www.crunch.com/locations?utm_campaign=Crunch%20Contracts&utm_source=hs_email&utm_medium=email&utm_content=68746559&_hsenc=p2ANqtz--WnPEpwpjVwsk8RflTCd8F-JovJjfbDy0azw04V56HqAS63WLaSc2Ip13XSWb5_93g4jocE5fF3vAZpZPvr6i8hvlPEQ&_hsmi=68746559">Crunch Home Club!</a> </h2>
                <MyFooter/>
            </React.Fragment>
        )
      }
}
export default AlreadySigned;