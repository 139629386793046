import React from 'react';

    
class MyPdfViewer extends React.Component {
    constructor(props) {
        super(props);
        this.viewerRef = React.createRef();
        this.backend = new props.backend();
        this.handleLoad = this.handleLoad.bind(this);
    }    
    
    
    
    componentDidUpdate(){
        if(this.props.checkBoxDisable === true && this.props.src!=='')
        {
        const { src } = this.props;
        const element = this.viewerRef.current;
        this.backend.init(src, element);
        element.children[0].addEventListener('load', this.handleLoad);
        }
        
    }

    handleLoad(){
        let element = this.viewerRef.current.children[0].contentDocument.getElementById('viewerContainer');
        element.addEventListener("scroll",  ()=> {
            if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
                this.props.enableCheckBox();
            }
        });
    }

    render() {
        if(this.props.src==='')
        {
            return(
                <div className="loader"></div>
            )
        }
        else{
            return (
                <div ref={this.viewerRef} id='viewer' style={{ width: '100%', height: '100%' }}>
                </div>
            )
         }
    }
}
 
export default MyPdfViewer;