import React from 'react';
    
class MyFooter extends React.Component {

    render() {
        return (
            <footer className="prime-footer">
                <span className="ftInfo"></span>
            </footer>
        )
      }
}
export default MyFooter;