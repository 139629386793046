import React from 'react';
import MyFooter from './footer';
import MyHeader from './header';
    
class ThankYou extends React.Component {

    render() {
        return (
            <React.Fragment>
                <MyHeader />
                    <h2 className = "thankYou">Thank You for signing the agreement. Enjoy your Crunch Journey!!</h2>
                <MyFooter/>
            </React.Fragment>
        )
      }
}
export default ThankYou;